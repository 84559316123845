<template>
	<!-- 农业用地数据监测 -->
	<div class="land_data_monitoring">
		<!-- 头部 -->
		<div class="land_head">
			<!-- 大棚选择器 -->
			<div class="select">
					<el-select v-model="value1" clearable  placeholder="请选择" @change="typeChange">
					    <el-option
					      v-for="item in options1"
					      :key="item.value"
					      :label="item.label"
					      :value="item.value">
					    </el-option>
					</el-select>
					<el-select v-model="value2" clearable  placeholder="请选择" @change="landChange">
					    <el-option
					      v-for="item in options2"
					      :key="item.id"
					      :label="item.yard_name"
					      :value="item.id">
					    </el-option>
					</el-select>
			</div>
			<div class="land_title">农业用地数据监测</div>
			<div class="back" @click="back">
				<i class="el-icon-back"></i>
				<span style="margin-left: 4px;">返回</span>
			</div>
		</div>
		<!-- 中间部分 -->
		<div class="land_middle">
			<div class="land_pic">
				<img src="../../assets/map/no_1.jpg" v-if="landId===1"/>
				<img src="../../assets/map/no_2.jpg" v-if="landId===2"/>
				<img src="../../assets/map/no_3.jpg" v-if="landId===3"/>
				<img src="../../assets/map/no_4.jpg" v-if="landId===4"/>
				<img src="../../assets/map/no_5.jpg" v-if="landId===5"/>
				<img src="../../assets/map/no_6.jpg" v-if="landId===6"/>
				<img src="../../assets/map/no_7.jpg" v-if="landId===7"/>
				<img src="../../assets/map/no_8.jpg" v-if="landId===8"/>
				<img src="../../assets/map/no_9.jpg" v-if="landId===9"/>
				<img src="../../assets/map/no_10.jpg" v-if="landId===10"/>
				<img src="../../assets/map/no_11.jpg" v-if="landId===11"/>
				<img src="../../assets/map/no_12.jpg" v-if="landId===12"/>
				<img src="../../assets/map/no_13.jpg" v-if="landId===13"/>
				<img src="../../assets/map/no_14.jpg" v-if="landId===14"/>
				<img src="../../assets/map/no_15.jpg" v-if="landId===15"/>
				<img src="../../assets/map/no_16.jpg" v-if="landId===16"/>
				<img src="../../assets/map/no_17.jpg" v-if="landId===17"/>
			</div>
			<!-- 监控摄像头 -->
			<div class="video" id="video-container" >
				<!-- <div class="play" id="video-container" v-show="player"></div> -->
				<!-- <FlvVideo></FlvVideo> -->
			</div>
			<img src="../../assets/map/jk.png" v-show="!player" class="videoImg" />
			<!-- 传感器设备运行状态 -->
			<div class="sensor">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>传感设备运行状态</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="sensor_list">
					<div class="sensor_item" v-for="(item,index) in sensorList" :key='index' v-if="sensorList.length>0">
						<div>{{item.device_name}}</div>
						<div>{{item.device_model}}</div>
						<div :class="item.device_status=='1'?'blue':'yellow'">{{item.device_status=='1'?'运行中':'离线'}}</div>
						<div class="check" @click="check(item)">查看详情</div>
					</div>
					<div v-if="sensorList.length==0" class="none">暂无传感设备</div>
				</div>
				
			</div>
		</div>
		<!-- 底部 -->
		<div class="land_bottom">
			<!-- 实时数据监测 -->
			<div class="real_time">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>实时数据监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="real_list">
					<div v-for="(item_label,index_label) in labelList" :key='index_label' class="data_item">
					   <div>{{item_label}}<i></i></div>
					   <span class="data_number" v-if="item_label=='空气温度'">{{realObj.kqwd}}°C</span>
					   <span class="data_number" v-if="item_label=='空气湿度'">{{realObj.kqsd}}%</span>
					   <span class="data_number" v-if="item_label=='10cm土壤温度'">{{realObj.trwd10}}°C</span>
					   <span class="data_number" v-if="item_label=='10cm土壤湿度'">{{realObj.trsd10}}%</span>
					   <span class="data_number" v-if="item_label=='20cm土壤温度'">{{realObj.trwd20}}°C</span>
					   <span class="data_number" v-if="item_label=='20cm土壤湿度'">{{realObj.trsd20}}%</span>
					   <span class="data_number" v-if="item_label=='30cm土壤温度'">{{realObj.trwd30}}°C</span>
					   <span class="data_number" v-if="item_label=='30cm土壤湿度'">{{realObj.trsd30}}%</span>
					   <span class="data_number" v-if="item_label=='40cm土壤温度'">{{realObj.trwd40}}°C</span>
					   <span class="data_number" v-if="item_label=='40cm土壤湿度'">{{realObj.trsd40}}%</span>
					   <span class="data_number" v-if="item_label=='60cm土壤温度'">{{realObj.trwd60}}°C</span>
					   <span class="data_number" v-if="item_label=='60cm土壤湿度'">{{realObj.trsd60}}%</span>
					   <span class="data_number" v-if="item_label=='80cm土壤温度'">{{realObj.trwd80}}°C</span>
					   <span class="data_number" v-if="item_label=='80cm土壤湿度'">{{realObj.trsd80}}%</span>
					   <span class="data_number" v-if="item_label=='光合有效辐射'">{{realObj.gh==='null'?0:realObj.gh}}LUX</span>
					   <span class="data_number" v-if="item_label=='二氧化碳浓度'">{{realObj['2y']}}%</span>
					   <span class="data_number" v-if="item_label=='营养液EC值'">{{realObj.ec}}mS/cm</span>
					   <span class="data_number" v-if="item_label=='营养液pH值'">{{realObj.ph}}</span>
					   <span class="data_number" v-if="item_label=='灌溉量'">{{realObj.ggl}}L</span>
					   <span class="data_number" v-if="item_label=='分区灌溉'">{{realObj.fq_gg?realObj.fq_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='日灌溉'">{{realObj.day_gg?realObj.day_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='周灌溉'">{{realObj.week_gg?realObj.week_gg:0}}次</span>
					   <span class="data_number" v-if="item_label=='最近灌溉时间'">{{realObj.gg_time}}</span>
					   <span class="data_number" v-if="item_label=='风速'">{{realObj.fs}}m/s</span>
					   <span class="data_number" v-if="item_label=='风向'">{{realObj.fx}}°</span>
					   <span class="data_number" v-if="item_label=='光照强度'">{{realObj.gz}}KLux</span>
					   <span class="data_number" v-if="item_label=='雨量'">{{realObj.yuliang}}mm</span>
					   <span class="data_number" v-if="item_label=='大气压'">{{realObj.dqy}}Kpa</span>
					   <span class="data_number" v-if="item_label=='噪声'">{{realObj.zs}}db</span>
					   <span class="data_number" v-if="item_label=='PM 2.5'">{{realObj.pm2}}ug/m³</span>
					   <span class="data_number" v-if="item_label=='PM 10'">{{realObj.pm10}}ug/m³</span>
					</div>
				</div>
			</div>
			<!-- 24小时数据监测图表 -->
			<div class="day_charts">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>24小时数据监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div id="myChart_dayData" ref='chart'></div>
			</div>
			<!-- 能源数据监测 -->
			<div class="ny_data">
				<div class="title">
					<div class="t_l"></div>
					<div class="t_r">
						<div>能源数据监测</div>
						<div class="line"></div>
					</div>
				</div>
				<div class="ny_list">
					<div class="ny_item">
						<div>发电设备数量<i></i></div>
						<div>{{energyObj.fadian_device}}</div>
					</div>
					<div class="ny_item">
						<div>配电设备数量<i></i></div>
						<div>{{energyObj.peidian_device}}</div>
					</div>
					<div class="ny_item">
						<div>今日累计发电量<i></i></div>
						<div>{{energyObj.hair_kwh_l}}Kwh</div>
					</div>
					<div class="ny_item">
						<div>今日累计用电量<i></i></div>
						<div>{{energyObj.use_kwh_l}}Kwh</div>
					</div>
					<div class="ny_item">
						<div>新能源用电占比<i></i></div>
						<div>{{energyObj.hair_kwh_l>0&&energyObj.use_kwh_l>0?parseFloat(energyObj.hair_kwh_l/energyObj.use_kwh_l*100).toFixed(2):0}}%</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import  EZUIKit from 'ezuikit-js'  //摄像头监控
import { sendMessageWebsocket } from '@/api/websocket'
import { getNyDeviceList,getYardList,getAgriculturalLandMonitor,realtimeDeviceMonitor,energy_jiance,getCameraUrl} from '@/api/index'
// 引入所有 ECharts 中所有的图表和组件
import * as echarts from 'echarts';
import FlvVideo from '../../components/AgriculturalLandDataMonitoring/FlvVideo.vue'
export default {
  name: 'AgriculturalLandDataMonitoring',
  data(){
	  return{
		 player:null,
		 options1:[
		 		{ value: '1',
		 		  label: '日光棚'
		 		}, {
		 		  value: '2',
		 		  label: '春秋棚'
		 		}, {
		 		  value: '3',
		 		  label: '大田'
		 		}                 
		 ],
		  value1:'',
		  value2:'',
		  options2:[],
		 sensorList:[],
		 labelList:['空气温度','空气湿度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','30cm土壤湿度','光合有效辐射','二氧化碳浓度','营养液EC值','营养液pH值','灌溉量','分区灌溉','日灌溉','周灌溉','最近灌溉时间','光照强度'],
		 labelList1:['空气温度','空气湿度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','30cm土壤湿度','光合有效辐射','二氧化碳浓度','营养液EC值','营养液pH值','灌溉量','分区灌溉','日灌溉','周灌溉','最近灌溉时间','光照强度'],
		 labelList2:['空气温度','空气湿度','10cm土壤温度','10cm土壤湿度','20cm土壤温度','20cm土壤湿度','40cm土壤温度','40cm土壤湿度','60cm土壤温度','60cm土壤湿度','80cm土壤温度','80cm土壤湿度','风速','风向','光合有效辐射','雨量','大气压','噪声','PM 2.5','PM 10','光照强度'],
		 // realObj:this.realObj1,
		 realObj:{kqwd:'25',kqsd:'30',trwd_2:'25',trsd_2:'10',trwd_4:'18',trsd_4:'5',trwd_6:'18',trsd_6:'18',gh:'150',co2:'20',ec:3,ph:7.6,ggl:10,fqgg:1,rgg:1,zgg:7,time:1636522201},
		  realObj1:{kqwd:'25',kqsd:'30',trwd_2:'25',trsd_2:'10',trwd_4:'18',trsd_4:'5',trwd_6:'18',trsd_6:'18',gh:'150',co2:'20',ec:3,ph:7.6,ggl:10,fqgg:1,rgg:1,zgg:7,time:1636522201},
		 realObj2:{kqwd:'25',kqsd:'30',trwd_2:'25',trsd_2:'10',trwd_4:'18',trsd_4:'5',trwd_6:'18',trsd_6:'18',gh:'150',fs:'3',fx:0,gzqd:200,yl:2,dqy:40,zs:40,pm2:36,pm10:50},
		 timeId:[],//定时器集合
		 xAxisName: [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24] ,// x轴名称
		 legendList:['空气温度','空气湿度','光合有效辐射','土壤温度','土壤湿度','二氧化碳浓度'],//折线名称集合
		 legendList1:['空气温度','空气湿度','光合有效辐射','土壤温度','土壤湿度','二氧化碳浓度'],//折线名称集合
		 legendList2:['空气温度','空气湿度','光合有效辐射','土壤温度','土壤湿度','雨量变化'],//折线名称集合
		 dataList:{},
		 yAxisName:['°C','%','ULX','mm'],//y轴名称
		 energyObj:{},//能源数据
		 landId:1
			 
	}
  },
  components:{
  	  FlvVideo
  },
  mounted () {
  		 // 获取chart盒子的宽度
  		 this.fullWidth = `${document.querySelector('#myChart_dayData').clientWidth}`// 默认值
  		 const that = this
  		 window.onresize = () => {
  		   return (() => {
  		     // window.fullWidth = document.documentElement.clientWidth
  		     window.fullWidth = document.querySelector('#myChart_dayData').clientWidth
  		     that.fullWidth = window.fullWidth
  		   })()
  		 }
  		// console.log('mounted')
  		// console.log(this.fullWidth)
		
		this.nyDeviceList(1)
		this.agriculturalLandMonitor(1)
		this.getRealData (1)
		this.getEnergyData()
		// this.initVoide()
		this.cameraUrl(1)
  },
  destroyed() {
  		  console.log('destroyed')
  		  console.log(this.timeId)
  		  // 清空该页面中所有的的定时器
  		  for(let i=0;i<this.timeId.length;i++){
  			   clearInterval(this.timeId[i])
  		  }
		  // 销毁图表实列，释放echarts资源
		  echarts.dispose(this.myChart);
		  this.myChart = null
		  if(this.player){this.player.stop()}
  },
  methods:{
	  // 获取监控摄像头信息
	  cameraUrl(id) {
	    const data = {
	  	  yard_id:id
	    }
	    // 请求数据
	    getCameraUrl(data)
	      .then((res) => {
	  	    console.log("获取监控设备token及播放地址")
	        console.log(res)
			if(res.code==='200'){
				console.log(this.player)
				if(this.player==null){
					// console.log(11111)
					document.getElementById('video-container').style.display="inline-block"
					this.player = new EZUIKit.EZUIKitPlayer({
					  id: 'video-container', // 视频容器ID
					  accessToken: res.data.accessToken,
					  url: res.data.url,
					  template: 'standard', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
					  plugin: ['talk'],  // 加载插件，talk-对讲
					  header:[],
					  // width:100%,
					  // height: 100%,
					});
					this.$nextTick(function () {
					  this.player.play();//播放
					})
				}else{
					console.log(2222)
					this.player.stop()
					  .then(()=>{
					    this.player.play(res.data.url);
					  });
				}
				
			}else{
				console.log('设置为空')
				this.player.stop()
				this.player=null
				document.getElementById('video-container').innerHTML=''
				// document.getElementById('video-container').style.display="none"
			}
	      })
	      .catch((err) => {
	        console.log(err)
	      })
	  },
	  initVoide(){
		console.log('视频配置')
		console.group("mounted 组件挂载完毕状态===============》");
		axios(
		  {
		    url: 'https://open.ys7.com/api/lapp/token/get',
		    method: 'post',
		    data: 'appKey=33747ce16c4041b4989891cc9d7c67aa&appSecret=a06d822d9122bb30f08436b1ab78bde5',
		    headers: {
		      'Content-Type': 'application/x-www-form-urlencoded'
		    }
			// url: 'https://open.ys7.com/jssdk/ezopen/demo/token',
			// method: 'get',
		  })
		  .then((res) => {
			console.log('okokomo')
		    console.log(res.data.data)
			var accessToken = res.data.data.accessToken;
			
			 this.player = new EZUIKit.EZUIKitPlayer({
			  id: 'video-container', // 视频容器ID
			  accessToken: accessToken,
			  // url: 'ezopen://open.ys7.com/G39444019/1.hd.live',
			  url: 'ezopen://open.ys7.com/G43369265/1.hd.live',
			  template: 'simple', // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版; theme-可配置主题；
			  plugin: ['talk'],                       // 加载插件，talk-对讲
			  // width:100%,
			  // height: 100%,
			});
			this.player.play();//播放
		  })
		  .catch(function(error) {
		    console.log(error)
		  })
	  },
	
	nyDeviceList (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  getNyDeviceList(data)
	    .then((res) => {
		  console.log("获取设备状态")
	      console.log(res)
		  console.log(res.data)
		  this.sensorList=res.data
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	agriculturalLandMonitor (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  getAgriculturalLandMonitor(data)
	    .then((res) => {
		  console.log("获取农业用地数据监测")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  // this.realObj=res.data.info
			  // this.dataList=res.data.datalist
			  this.dataList=res.data
			  this.$nextTick(function () {
			    this.drawLine();
			   })
			 
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getRealData (id) {
	  const data = {
		  yard_id:id
	  }
	  // 请求数据
	  realtimeDeviceMonitor(data)
	    .then((res) => {
		  console.log("获取实时数据")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  this.realObj=res.data
			 
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getEnergyData () {
	  const data = {}
	  // 请求数据
	  energy_jiance(data)
	    .then((res) => {
		  console.log("获取首页等地的能源数据监测")
	      console.log(res)
		  console.log(res.data)
		  if(res.code===200){
			  this.energyObj=res.data
		  }
	    })
	    .catch((err) => {
	      console.log(err)
	    })
	},
	getLandList(type){
		console.log("获取场地")
		// 获取表格数据
		const data = {
		 type:type
		}
		// 请求数据
		getYardList(data)
		  .then((res) => {
				  console.log(res)
				  console.log(res.data)
				  // this.options2=res.data
				  if(type===''){
					  this.allOptions=res.data
				  }else{
					 this.options2=res.data 
				  }
		  })
		  .catch((err) => {
		    console.log(err)
		  })
	},
	// 选择框事件
	handleChange(value) {
	    console.log(value);
		// //选择的时大田时用labelList2，realObj2，legendList2
		this.labelList=value[0]=='3'?this.labelList2:this.labelList1
		this.realObj=value[0]=='3'?this.realObj2:this.realObj1
		this.legendList=value[0]=='3'?this.legendList2:this.legendList1
		console.log(this.legendList)
		this.$forceUpdate()
	},
	// 场地种类选择
	typeChange(value){
		console.log(value)
		this.landType=value
		this.getLandList(value)
	},
	landChange(e){
		console.log(e)
		this.landId=e
		// //选择的时大田时用labelList2，realObj2，legendList2
		this.labelList=this.landType=='3'?this.labelList2:this.labelList1
		this.realObj=this.landType=='3'?this.realObj2:this.realObj1
		this.legendList=this.landType=='3'?this.legendList2:this.legendList1
		console.log(this.legendList)
		this.$forceUpdate()
		this.nyDeviceList (e)
		this.agriculturalLandMonitor (e)
		this.getRealData (e)
		this.cameraUrl(e)
	},
	// 设备查看详情
	check(item){
		console.log(item)
		this.$router.push({
			path:'/equipmentDetail',
			query:{
				id: item.id
			}	  
		})
	},
	// 返回
	back(){
		this.$router.go(-1)
	},
	// 24小时数据监测图表
	drawLine () {
		    //      if(this.myChart){
					 // console.log('清空画布')
		    //      	 this.myChart.clear()
					 // // 清空该页面中所有的的定时器
					 // for(let i=0;i<this.timeId.length;i++){
					 //   	clearInterval(this.timeId[i])
					 // }
		    //      }
			   // 基于准备好的dom，初始化echarts实例
			           this.myChart = echarts.init(document.getElementById('myChart_dayData'));
			           this.myChart.resize({
			             width: this.$refs.chart.clientWidth,
			             height: this.$refs.chart.clientHeight
			           })
			           // 指定图表的配置项和数据
			           var option = {
						   ////动画效果
						   animationDuration: 10000,
						   color: ['#80FFA5', '#00DDFF', '#37A2FF', '#FF0087', '#FFBF00','#ED9845'],
						   title: {
						        text: '24小时数据监测图表',
						    	textStyle:{
						    		color: '#A5EEEB',
						    	},
						    },
						    tooltip: {
						      trigger: 'axis',
						      axisPointer: {
						        type: 'cross',
						        label: {
						          backgroundColor: '#6a7985'
						        }
						      },
							  backgroundColor:'#0B1326',//通过设置rgba调节背景颜色与透明度
							  borderColor:'gray',
							  textStyle:{
							  	align:'left',///标签内容左对齐
							  	color: '#FFFFFF'
							  },
							  
							  // 自定义节点标签内容
							  formatter(params){
							  	  var date = new Date()// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
							  	  const Y = date.getFullYear() + '年'
							  	  const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
							  	  const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
							  	  let time= Y + M + D 
							  	  let str=time+'  '+params[0].axisValue+'时'+'<br>'						   
							      params.forEach((item, idx) =>{
							  		str+=item.marker+' '+item.seriesName+' : '+item.data	
									switch (item.seriesName){
									     case '空气温度':
									         str += '°C';
									         break;
									     case '空气湿度':
									         str += '%';
									         break;
									     case '光合有效辐射':
									         str += 'LUX';
									         break;
										 case '土壤温度':
										     str += '°C';
										     break;
										 case '土壤湿度':
										     str += '%';
										     break;
										 case '二氧化碳浓度':
											 str += '%';
										    break;
										case '雨量变化':
										   str += 'mm';
										   break;
									     default:
									         str += '';		    
									}						
							  		str += idx === params.length -1? '': '<br/>'							   
							      })   
							  		return str;
							   }
						    },
						    legend: {
						      data:this.legendList,
						      textStyle:{
						      	  color: '#FFFFFF'
						      },
						      // orient: 'vertical',  //垂直显示
						      y: '30',    //延Y轴居中
						      x: 'center' ,//居右显示
							  itemWidth:30,//图例的宽度
							  itemHeight:10,//图例的高度
						    },
						    toolbox: {
						      feature: {
						        saveAsImage: {
						    		show:true,
						         backgroundColor :'balck',
						    		pixelRatio: 3
						    	}
						      },
						    },
						    grid: {
						      left: '3%',
						      right: '5%',
						      bottom: '0%',
							  top:'80',
						      containLabel: true
						    },
						    xAxis: [
						      {
						        type: 'category',
						        boundaryGap: false,
								splitLine:{
									show:false
								},
								// 设置坐标轴字体颜色和宽度
								axisLine:{
									lineStyle: {
									    color: '#FFFFFF'
									}	,
								},
						        data: this.xAxisName
						      }
						    ],
						    yAxis: [
						  //     {
						  //       type: 'value',
								// splitLine:{
								// 	show:false
								// },
								// // 设置坐标轴字体颜色和宽度
								// axisLine:{
								// 	lineStyle: {
								// 	    color: '#FFFFFF'
								// 	}	,
								// },
								
						  //     }
						  { // 第一个y轴位置在左侧 温度
						    position: 'left',
						    type: 'value',
						    name: this.yAxisName[0],
						    // name: this.Ystyle(this.yAxisName[0]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]   //想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的名称颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      // color: '#FFFFFF',
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						      // color: ['red'],
						      // width: 0.5,
						      // type: 'solid'
						    }
						  },
						  { // 第二个y轴在右侧
						    position: 'right',
						    type: 'value',
						    name: this.yAxisName[1],
						    // name: this.Ystyle(this.yAxisName[1]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 0, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  { // 第三个y轴也在左侧，距第二个60个像素
						    position: 'left',
						    offset: 30,
						    type: 'value',
						    name: this.yAxisName[2],
						    // name: this.Ystyle(this.yAxisName[2]), // 将y轴的名称竖着排版
						    nameRotate: '0', // y轴名称旋转度数
						    nameGap: 10, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]// 想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      // color: this.colors[2], // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  { // 第四个y轴也在左侧，距第二个60个像素
						    position: 'right',
						    offset: 30,
						    type: 'value',
						    name: this.yAxisName[3],
						    nameGap: 10, // 名称与坐标轴间距
						    // nameLocation: 'center', // 名称位置调整
						    nameTextStyle: {
						      // padding: [0, 0, 0, 0]// 想要是坐标轴名称放到任意位置的话,可以用这个来调整
						      color: '#FFFFFF', // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10 // 名称字体大小
						    },
						    axisLine: {
						      show: true
						    },
						    axisTick: { // y轴刻度线
						      show: true
						    },
						    axisLabel: {
						      color: '#FFFFFF',
						      // color: this.colors[2], // 设置y轴的单位数字颜色和它对应的曲线颜色一致
						      fontSize: 10
						    },
						    splitLine: { // 网格线
						      show: false
						    }
						  },
						  
						],
						    series: [
						      {
						        name: this.legendList[0],
						        type: 'line',
								yAxisIndex: 0,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(128, 255, 165)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(1, 191, 236)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [140, 232, 101, 264, 90, 340, 250]
								data: this.dataList.kqwd
						      },
						      {
						        name: this.legendList[1],
						        type: 'line',
								yAxisIndex: 1,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(0, 221, 255)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(77, 119, 255)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [120, 282, 111, 234, 220, 340, 310]
								data: this.dataList.kqsd
						      },
						      {
						        name: this.legendList[2],
						        type: 'line',
								yAxisIndex: 2,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(55, 162, 255)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(116, 21, 219)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [320, 132, 201, 334, 190, 130, 220]
								data: this.dataList.gh
						      },
						      {
						        name: this.legendList[3],
						        type: 'line',
								yAxisIndex: 0,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(255, 0, 135)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(135, 0, 157)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [220, 402, 231, 134, 190, 230, 120]
								data: this.dataList.trwd
						      },
						      {
						        name: this.legendList[4],
						        type: 'line',
								yAxisIndex: 1,
						        stack: 'Total',
						        smooth: true,
						        lineStyle: {
						          width: 0
						        },
						        showSymbol: false,
						        label: {
						          show: true,
						          position: 'top'
						        },
						        areaStyle: {
						          opacity: 0.8,
						          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
						            {
						              offset: 0,
						              color: 'rgba(255, 191, 0)'
						            },
						            {
						              offset: 1,
						              color: 'rgba(224, 62, 76)'
						            }
						          ])
						        },
						        emphasis: {
						          focus: 'series'
						        },
						        // data: [220, 302, 181, 234, 210, 290, 150]
								data: this.dataList.trsd
						      },
							  {
							    name: this.legendList[5],
							    type: 'line',
								yAxisIndex: this.legendList[5]=='雨量变化'?3:1,
							    stack: 'Total',
							    smooth: true,
							    lineStyle: {
							      width: 0
							    },
							    showSymbol: false,
							    areaStyle: {
							      opacity: 0.8,
							      color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
							        {
							          offset: 0,
							          color: 'rgba(237,152,69)'
							        },
							        {
							          offset: 1,
							          color: 'rgba(255,153,51,0.5)'
							        }
							      ])
							    },
							    emphasis: {
							      focus: 'series'
							    },
							    // data: [280, 300, 231, 144, 180, 80, 299]
								data: this.legendList[5]=='雨量变化'?this.dataList.ylbh:this.dataList.co2
							  },
						    ]
			           };
					   // 先清空图表内容
					   this.myChart.clear()
					   // 清空该页面中所有的的定时器
					   for(let i=0;i<this.timeId.length;i++){
					     	clearInterval(this.timeId[i])
					   }
			           // 使用刚指定的配置项和数据显示图表。
			           this.myChart.setOption(option);
					   
					   //动态循环显示数据节点
					   this.dataLableChange(this.myChart,option,1)
	},
	// 动态循环显示数据节点方法
	dataLableChange(myChart,option,i){
			   // console.log('shenme')
			   //动态循环显示数据节点
			   var app = {
			       currentIndex: -1,
			   };
			   this.timeId[i]=setInterval(function() {
			   	   // console.log('start'+i)
			       var dataLen = option.series[0].data.length;
			       // 取消之前高亮的图形
			       myChart.dispatchAction({
			           type: 'downplay',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			       app.currentIndex = (app.currentIndex + 1) % dataLen;
			   
			       // 高亮当前图形
			       myChart.dispatchAction({
			           type: 'highlight',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex,
			       });
			   
			       // 显示 tooltip
			       myChart.dispatchAction({
			           type: 'showTip',
			           seriesIndex: 0,
			           dataIndex: app.currentIndex
			       });
			   
			   }, 5000); 
			   
	},
	// 纵坐标的值竖着显示的实现方法
	Ystyle (value) {
	  // console.log(value)
	  if (value) {
	    // 如果传入的值不为空的时候把y轴的文字改为竖版显示
	    const str = value.split('')
	    return str.join('\n')
	  }
	},
	// 格式化后台传来的时间戳（年月日时分秒）
	timeFormate (timeStamp) {
	      var date = new Date(timeStamp * 1000)// 时间戳为10位需*1000，时间戳为13位的话不需乘1000zan
	      const Y = date.getFullYear() + '年'
	      const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '月'
	      const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + '日'
	      const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':'
	      // const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':'
		  const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) 
	      const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds())
	      return Y + M + D 
	},
  },
  // 监听屏幕变化
  watch: {
    // 如果 `fullHeight ` 发生改变，这个函数就会运行
    fullHeight: function (val) {
      if (!this.timer) {
        this.fullWidth = val
        this.drawLine()
        console.log(val)
        this.timer = true
        const that = this
        setTimeout(function () { // 频繁触发 resize 函数，会导致页面很卡
          that.timer = false
        }, 100)
      }
    },
  }
}
</script>

<style scoped="scoped">
.land_data_monitoring {
    width: 100%;
    height: 100%;
	padding: 0 30px 30px 30px;
	box-sizing: border-box;
	position: relative;
  }
  .land_head{
	  width: 100%;
	  height: 78px;
	  margin-bottom: 30px;
	  display: flex;
	  align-items:flex-end;
	  justify-content: start;
	  position: relative;
  }
  .select{
	  /* width: 553px; */
	  height: 48px;
  }
  .land_title{
	  position: absolute;
	  left: 50%;
	  transform: translateX(-50%);
	  width: 554px;
	  height: 78px;
	  line-height: 78px;
	  font-size: 28px;
	  font-family: PingFang SC;
	  color: #FFFFFF;
	  margin-left: 100px;
	  letter-spacing: 2px;
	  /* background: #606266; */
	  background: url(../../assets/title_bg.png);
	  background-size: contain;
  }
  .back{
	  position: absolute;
	  right: 0;
	  bottom: 0;
	  width: 135px;
	  height: 48px;
	  line-height: 48px;
	  font-size: 14px;
	  font-family: PingFang SC;
	  letter-spacing: 2px;
	  color: #FFFFFF;
	  /* background: #0C0F10; */
	  background: url(../../assets/back_bg.png);
	  border: 1px solid #4B5052;
	  display: flex;
	  align-items: center;
	  justify-content: center;
	  cursor: pointer;
	  background-size: cover;
  }
  .back i{
	  font-size: 18px;
  }
  .land_middle{
	  width: 100%;
	 /* height: 391px; */
	  height:calc(40% - 15px);
	  position: relative;
	  margin-bottom: 30px;
	  display: flex;
	  justify-content: space-between;
	  align-items: center;
	 /* background-color: #FB5C55; */
  }
  .land_pic{
	  /* width: 553px; */
	  width: 31%;
	  height: 100%;
	  /* height: 391px; */
	 /* height: 100%; */
	/* background-color: red; */
  }
  .land_pic img{
	  width: 100%;
	  height: 100%;
  }
  .video{
	  /* width: 694px; */
	  width: 38%;
	 /* height: 390px; */
	  height: 100%;
	  background-color: #000000;
  }
  .videoImg{
	  width: 38%;
	  height: 100%;
	  position: absolute;
	  left: 32.5%;
	  top: 0;
	  
  }
  .sensor{
	  /* width: 552px; */
	  width:28% ;
	  /* height: 390px; */
	   height: 100%;
	  background: rgba(10, 23, 56, 0.2);
	  padding: 0 20px;
	  box-sizing: border-box;
	  border: 1px solid #36CFC9;
  }
  .title{
  	  width: 100%;
  	  height: 42px;
  	  display: flex;
  	  align-items: flex-end;
  	  aljustify-content: start;
  	  text-align: left;
	  margin-top: 0;
  }
  .t_l{
  	  width: 33px;
  	  height: 34px;
  	  background: url(../../assets/jiankong/quan_o.png) no-repeat;
  	  margin-right: 14px;
	  background-size: contain;
  }
  .t_r{
  	  width: calc(100% - 47px);
  	  font-size: 16px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  height: 42px;
  	  line-height: 42px;
  }
  .line{
  	  width: 100%;
  	  height: 1px;
  	  background: url(../../assets/jiankong/line.png);
  }
  .sensor_list{
	  /* width: 508px; */
	  width: 100%;
	 /* height: 324px; */
	  height: calc(100% - 65px);
	  overflow: auto;
	  margin-top: 10px;
  }
  .sensor_item{
	  /* width: 500px; */
	  width: calc(100% - 5px);
	  height: 40px;
	  background-color: rgba(11,19,38,0.5);
	  margin-bottom: 8px;
	  display: flex;
	  align-items: center;
	  justify-content: space-between;
	  color: #FFFFFF;
	  font-size: 12px;
	  padding:0 10px;
	  box-sizing: border-box;
  }
  .sensor_item div{
	  width: 150px;
	  text-align: center;
  }
  .sensor_item div:nth-child(3){
  	  width: 100px;
  	  text-align: left;
  }
  .check{
	  width: 60px!important;
	  height: 24px;
	  line-height: 24px;
	  text-align: center!important;
	  background: rgba(54, 207, 201, 0.2);
	  border: 1px solid #36CFC9;
	  color: #36CFC9;
	  cursor: pointer;
  }
  .blue{
	  color:#36CFC9 ;
  }
  .yellow{
  	  color:#ED9845 ;
  }
  
  .land_bottom{
	 width: 100%;
	 /* height: 445px; */
	 height:calc(60% - 120px);
	 display: flex;
	 justify-content: space-between;
	/* background-color: yellow; */
  }
  .real_time{
	 /* width: 531px; */
	  width: 30%;
	  /* height: 445px; */
	  height: 100%;
	  background: rgba(10, 23, 56, 0.2);
	  padding: 0 20px;
	  box-sizing: border-box;
	  border: 1px solid #36CFC9;
  }
  .real_list{
	  width: 100%;
	  height: calc(100% - 42px);
	  padding-top: 20px;
	  box-sizing: border-box;
	  overflow: auto;
	  /* background-color: red; */
  }
  .data_item{
  	  float: left;
  	  /* margin-right: 40px; */
	  margin-right: 10%;
  	  font-size: 14px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #BFF2F0;
  	  margin-bottom: 4%;
  	  height: 18px;
  	  display: flex;
  	  align-items: center;
  }
  .data_item:nth-child(2n){
  	  margin-right: 0;
  }
  .data_item div{
  	  display: inline-block;
  	  width: 100px;
  	  text-align:justify;
  	  height: 16px;
  	  line-height: 16px;
  	  
  }
  .data_item div i{
    display:inline-block;
  	height: 1px;
    width:100%;
  }
  .data_number{
  	  display: inline-block;
  	  min-width: 70px;
  	  margin-left: 10px;
  	  font-size: 15px;
  	  font-family: PingFang SC;
  	  font-weight: bold;
  	  color: #FFFFFF;
  }
  
  .day_charts{
	 /* width: 868px; */
	  width: 45%;
	  /* height: 446px; */
	  height: 100%;
	  padding: 0 10px 0 20px;
	  box-sizing: border-box;
	  background: rgba(10, 23, 56, 0.2);
	  border: 1px solid #36CFC9;
  }
  #myChart_dayData{
     width: 100%;
     height:calc(100% - 70px);
	 margin-top: 20px;
   }
  .ny_data{
	  /* width: 397px; */
	  width: 23%;
	  /* height: 446px; */
	  height: 100%;
	  padding: 0 20px;
	  box-sizing: border-box;
	  background: rgba(10, 23, 56, 0.2);
	  border: 1px solid #36CFC9;
  }
  .ny_list{
	  width: 100%;
	  height: calc(100% - 42px);
	  display: flex;
	  flex-flow: column;
	  /* background-color: #1CCA81; */
  }
  .ny_item{
	  display: flex;
	  align-items: center;
	  justify-content: start;
	  font-size: 14px;
	  font-family: PingFang SC;
	  /* font-weight: bold; */
	  color: #BFF2F0;
	  flex: 1;
	  padding-left: 35px;
	  box-sizing: border-box;
  }
  .ny_item div:first-child{
  	 width: 120px;
	 height: 16px;
	 line-height: 16px;
	 text-align: justify;
  }
  .ny_item div:first-child i{
    display:inline-block;
  	height: 1px;
    width:100%;
  }
  .ny_item div:last-child{
	  color: #FFFFFF;
	  margin-left: 56px;
	  font-weight: bold;
  }
  
 /deep/.el-select{
 	  margin-right: 30px;
 }
 /deep/.el-select .el-input__inner{
 	  height: 48px;
 	  width: 150px;
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
 /deep/.el-select .el-input.is-focus .el-input__inner{
 	  border: 1px solid #36CFC9;
 }
 /deep/.el-input__inner{
 	  height: 48px;
 	  width: 200px;
 	  background-color: rgba(11,19,38,0.5);
 	  border: 1px solid #36CFC9;
 	  color: #FFFFFF !important;
 }
 /deep/.el-input__suffix{
 	  color: #FFFFFF !important;
 } 
  
  
  
  /deep/.el-cascader .el-input .el-input__inner{
	  height: 48px;
	  width: 553px;
	  background-color: rgba(11,19,38,0.5);
	  /* background: url(../../assets/select_bg.png) no-repeat; */
	  background-size: cover;
	  border: 1px solid #36CFC9;
	  color: #FFFFFF !important;
  }
.none{
	color: #CCCCCC;
	font-size: 20px;
	position: relative;
	top:50%;
	transform: translateY(-50%);
}
</style>
<style>
/* // 级联选择器样式 */
.train-tree {
    background-color: rgba(11,19,38);
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
   /* font-weight: 400; */
    color: #fff!important;
    line-height: 20px;
    border: none;
    }
.train-tree .el-cascader-menu{
	color: #FFFFFF;
}
.train-tree .el-cascader-node:hover{
	background-color: rgba(0,0,0,1);
}
.train-tree .el-cascader-node.in-active-path, .el-cascader-node.is-active, .el-cascader-node.is-selectable.in-checked-path{
	color: #36CFC9;
}
</style>


